//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mdiClose, mdiPlay } from "@mdi/js";

export default {
  data() {
    return {
      dialog: false,
      mdiClose,
      mdiPlay,
      mounted: false,
    };
  },
  computed: {
    videoSrc() {
      if (this.$vuetify.breakpoint.width < this.$vuetify.breakpoint.height) {
        if (this.$vuetify.breakpoint.width >= 480) return "vertical-1080w";
        if (this.$vuetify.breakpoint.width >= 384) return "vertical-720w";
        return "vertical-576w";
      }
      if (this.$vuetify.breakpoint.height >= 600) return "horizontal-1080h";
      if (this.$vuetify.breakpoint.height >= 480) return "horizontal-900h";
      return "horizontal-720h";
    },
  },
  watch: {
    dialog(dialog) {
      this.$nextTick(() => this.$refs.video[dialog ? "play" : "pause"]());
    },
  },
  mounted() {
    this.mounted = true;
  },
};
